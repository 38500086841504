import React, { useEffect, useState } from 'react';
import {
  StyledBoxContainer,
  StyledCircle,
  StyledCircleContainer,
  StyledGridContainer,
  StyledGridItem

  //   StyledHeaderBox
} from '../../features/procurementAttributes/commonStyles/styled';
import { Button, Grid, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// import { contractAmendmentArray } from 'constants/contractAmendment';
import { RenderForm } from '../../utils/helper';
import { useFieldArray, useFormContext } from 'react-hook-form';
//import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { CONTRACT_AMENDMENT_ROW } from 'constants';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DeleteTwoTone } from '@mui/icons-material';
import UndoIcon from '@mui/icons-material/Undo';

//import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// const contractAmendmentObject = {
//   isDisplayed: false,
//   data: contractAmendmentArray
// };

const ContractAmendment = ({
  data,
  scrollToBottomHandler,
  // contractAmendmentDeleteFlag,
  savedContractAmendments,
  resetContractAmendmentHandler
}) => {
  // const [fieldValues, setFieldValues] = useState([]);
  const [contractAmendmentGroup, setContractAmendmentGroup] = useState({
    isDisplayed: true,
    data: data.data.filter((el) => el.family === 'contractAmendment')
  });
  const { control, watch, setValue, getValues } = useFormContext();
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'submission.contract.contractAmendment'
  });
  const watchedFields = watch('submission.contract.contractAmendment');
  // console.log('lets see watched fields', watchedFields);

  useEffect(() => {
    if (data) {
      setContractAmendmentGroup(() => ({
        isDisplayed: true,
        data: data.data.filter((el) => el.family === 'contractAmendment')
      }));
    }
  }, [data]);

  // useEffect(() => {
  //   //console.log('Watched fields after updating:', watchedFields);
  //   watchedFields.forEach((row, index) => {
  //     const DE236Value = row?.DE236;
  //     if (DE236Value !== 'Y') {
  //       const additionalProperties = {
  //         DE233: null,
  //         DE237: null,
  //         DE238: null,
  //         DE239: '',
  //         DE240: 0,
  //         DE241: null,
  //         DE242: null,
  //         DE243: ''
  //       };
  //       // Merge additional properties with the existing properties of the row
  //       const updatedRow = {
  //         ...row, // Existing properties
  //         ...additionalProperties // Additional properties
  //       };
  //       // Update the row in the array
  //       setValue(`submission.contract.contractAmendment[${index}]`, updatedRow);
  //       trigger(`submission.contract.contractAmendment[${index}]`);
  //     }
  //   });
  // }, [JSON.stringify(watchedFields)]);
  // Function to validate and parse date string into Date object
  // const parseDate = (value) => {
  //   // console.log('Object.prototype.toString.call(d)', Object.prototype.toString.call(value));
  //   if (Object.prototype.toString.call(value) === '[object Date]') {
  //     // it is a date
  //     if (isNaN(value)) {
  //       // d.getTime() or d.valueOf() will also work
  //       // date object is not valid
  //       console.log('I am in ISNAN', isNaN(value));
  //       return null;
  //     } else {
  //       // date object is valid
  //       console.log('I am in nested if else', value);
  //       return value;
  //     }
  //   } else if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
  //     console.log('I am in nested if else type string', value);
  //     return value;
  //   } else {
  //     // not a date object
  //     console.log('I am in else', value);
  //     return null;
  //   }
  // };
  useEffect(() => {
    const watchedFields = watch('submission.contract.contractAmendment');
    // console.log('Watched fields after updating:', watchedFields);
    watchedFields.forEach((_, index) => {
      // const DE236Value = row.DE236;
      // const rowContractAmendId = row.contractAmendId;
      //BELOW Watch is important for keeping track of DE236 when we save our submission as form will be reset
      watch(`submission.contract.contractAmendment[${index}].DE236`);
      // If DE236Value is 'Y', add additional fields
      // if (DE236Value !== 'Y') {
      // const additionalProperties = {
      //   DE237: null,
      //   DE238: null,
      //   DE239: '',
      //   DE240: 0,
      //   DE241: null,
      //   DE242: null,
      //   DE243: ''
      // };
      // const additionalProperties = {
      //   DE237: parseDate(row.DE237),
      //   DE238: parseDate(row.DE238),
      //   DE239: row.DE239 ?? '', // Assuming DE239 is not a date field
      //   DE240: row.DE240 ?? 0, // Assuming DE240 is not a date field
      //   DE241: parseDate(row.DE241),
      //   DE242: parseDate(row.DE242),
      //   DE243: row.DE243 ?? '' // Assuming DE243 is not a date field
      // };
      // setValue(`submission.contract.contractAmendment[${index}]`, {
      //   ...row, // Existing properties
      //   ...additionalProperties
      // });
      // trigger(`submission.contract.contractAmendment[${index}]`);
      // }
    });
  }, [JSON.stringify(watchedFields)]);

  const handleAddRow = () => {
    const newRow = {
      id: uuidv4(),
      ...CONTRACT_AMENDMENT_ROW
    };
    // setRows((prevRows) => [...prevRows, newRow]);
    // const { ...filteredObject } = newRow;
    append(newRow);
    // Below is required to watch elements DE236 as we add filtered object DE236 defines whther other  Data elements should be added or not
    // START
    const amendmentArray = watch('submission.contract.contractAmendment');
    amendmentArray.forEach((_, index) => {
      watch(`submission.contract.contractAmendment[${index}].DE236`);
    });
    // END
    scrollToBottomHandler();
  };
  const savedContractAmendmentsHandler = (myValues, index) => {
    return (
      savedContractAmendments &&
      savedContractAmendments.contractAmendment &&
      savedContractAmendments.contractAmendment.length > index &&
      // myValues[index].DE222 === savedContractAmendments.contractAmendment[index].DE222
      savedContractAmendments.contractAmendment.some(
        (item) => item.contractAmendId === myValues[index].contractAmendId
      )
    );
  };
  const contractAmendmentDeleteUnDeleteHalder = (action, index, myValues) => {
    if (
      // savedContractAmendments &&
      // savedContractAmendments.contractAmendment &&
      // savedContractAmendments.contractAmendment.length > index &&
      // myValues[index].DE222 === savedContractAmendments.contractAmendment[index].DE222
      savedContractAmendmentsHandler(myValues, index)
    ) {
      // console.log('I am in delete');
      if (action === 'delete') {
        setValue(`submission.contract.contractAmendment[${index}].deleteFlg`, 'Y');
      } else {
        setValue(`submission.contract.contractAmendment[${index}].deleteFlg`, 'N');
      }
      resetContractAmendmentHandler();
    } else {
      // console.log('I am in else', savedContractAmendments);
      // console.log('I am in else myValues', myValues);
      // const checkValues = getValues('submission.contract.contractAmendment');
      // console.log('lets check values', checkValues);
      remove(index);
    }
  };
  // console.log('fields', fields);
  return (
    <Box>
      <StyledGridContainer
        container
        // style={{ padding: '5px', flex: 1 }}
      >
        <StyledGridItem item xs={6}>
          <StyledBoxContainer>
            <StyledCircleContainer>
              <StyledCircle />
            </StyledCircleContainer>
            <Typography variant="h3">Contract Amendment Log</Typography>
          </StyledBoxContainer>
        </StyledGridItem>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              handleAddRow();
            }}>
            Add
          </Button>
        </Grid>
      </StyledGridContainer>
      <StyledGridContainer container style={{ padding: '5px', flex: 1, border: '1px ' }}>
        {/* {fields.map((formDataConfig, index) => (
          <Grid item key={formDataConfig.id} container alignItems="center" marginBottom="10px">
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              // onClick={() => handleDeleteForm(formDataConfig.id)}
              onClick={() => remove(index)}
              sx={{ width: '100%' }}></Button>
            <RenderForm formDataConfig={formDataConfig} />
            <div>
              <pre>{JSON.stringify(fields, null, 2)}</pre>
            </div>
            <Divider />
          </Grid>
        ))} */}
        {/* START OF TEST */}
        {fields.map((obj, index) => {
          // Transform each 'floor' object into the desired 'formDataConfig' format
          const formDataConfig = {
            id: obj.id,
            isDisplayed: true,
            data: contractAmendmentGroup.data.map((item) => ({
              ...item,
              elementId: index
            }))
          };
          const myValues = getValues('submission.contract.contractAmendment');
          // console.log('lets see getvalues', myValues);
          // console.log('LETS SEE FORMDATA CONFIG', formDataConfig);
          const Administrative = {
            ...formDataConfig,
            data: formDataConfig.data.slice(0, 14)
          };
          // console.log('lets see admin group', Administrative);
          const AmendmentResourcing = {
            ...formDataConfig,
            data: formDataConfig.data.slice(14, 20)
          };
          const AmendmentLead = {
            ...formDataConfig,
            data: formDataConfig.data.slice(20, 22)
          };
          return (
            // myValues[index].deleteFlg !== 'Y' && (
            <Box key={obj.id || obj.contractAmendId} sx={{ m: '0.25rem', minWidth: '100%' }}>
              <Accordion sx={{ width: '100%' }}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header">
                  {savedContractAmendments &&
                  savedContractAmendments.contractAmendment &&
                  savedContractAmendments.contractAmendment.length > index &&
                  savedContractAmendments.contractAmendment[index].DE222 ? (
                    <>
                      <Typography>{`CAAR No. ${savedContractAmendments.contractAmendment[index].DE222}`}</Typography>
                      <Typography color="secondary" sx={{ ml: '2rem' }}>
                        {`Deleted: ${
                          savedContractAmendments.contractAmendment[index].deleteFlg === 'Y'
                            ? 'Yes'
                            : 'No'
                        }`}
                      </Typography>
                    </>
                  ) : (
                    <Typography>Contract Amendment</Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      // border: '1px solid red',
                      minWidth: '100%'
                    }}>
                    {/* <Button
                          startIcon={<DeleteTwoTone />}
                          onClick={() => {
                            if (
                              savedContractAmendments &&
                              savedContractAmendments.contractAmendment &&
                              savedContractAmendments.contractAmendment.length > index &&
                              myValues[index].DE222 ===
                                savedContractAmendments.contractAmendment[index].DE222
                            ) {
                              setValue(
                                `submission.contract.contractAmendment[${index}].deleteFlg`,
                                'Y'
                              );
                              resetContractAmendmentHandler();
                            } else {
                              remove(index);
                            }
                          }}
                          sx={{ border: '1px solid red', marginRight: 2 }}
                          color="error"
                          fontSize="Large">
                          DELETE
                        </Button> */}
                    <Button
                      startIcon={<DeleteTwoTone />}
                      onClick={() => {
                        contractAmendmentDeleteUnDeleteHalder('delete', index, myValues);
                      }}
                      disabled={
                        savedContractAmendments &&
                        savedContractAmendments.contractAmendment &&
                        savedContractAmendments.contractAmendment.length > index &&
                        !(
                          savedContractAmendments.contractAmendment.some(
                            (item) => item.contractAmendId === myValues[index].contractAmendId
                          ) && savedContractAmendments.contractAmendment[index].deleteFlg === 'N'
                        )
                      }
                      sx={{ border: '1px solid red', marginRight: 2 }}
                      color="error"
                      fontSize="Large">
                      DELETE
                    </Button>
                    {savedContractAmendmentsHandler(myValues, index) && (
                      <Button
                        variant="outlined"
                        id="undelete"
                        startIcon={<UndoIcon />}
                        disabled={
                          !(savedContractAmendments.contractAmendment[index].deleteFlg === 'Y')
                        }
                        onClick={() => {
                          contractAmendmentDeleteUnDeleteHalder('undelete', index, myValues);
                        }}>
                        UNDELETE
                      </Button>
                    )}
                  </Grid>
                  {myValues[index].deleteFlg !== 'Y' && (
                    <>
                      <StyledGridContainer container style={{ padding: '5px', flex: 1 }}>
                        <StyledGridItem item xs={12}>
                          <StyledBoxContainer>
                            <StyledCircleContainer>
                              <StyledCircle />
                            </StyledCircleContainer>
                            <Typography variant="h3">Administrative</Typography>
                          </StyledBoxContainer>
                        </StyledGridItem>
                        <RenderForm formDataConfig={Administrative} />
                      </StyledGridContainer>
                      {myValues[index].DE236 === 'Y' && (
                        <StyledGridContainer container style={{ padding: '5px', flex: 1 }}>
                          <StyledGridItem item xs={12}>
                            <StyledBoxContainer>
                              <StyledCircleContainer>
                                <StyledCircle />
                              </StyledCircleContainer>
                              <Typography variant="h3">Amendment Resourcing</Typography>
                            </StyledBoxContainer>
                          </StyledGridItem>
                          <RenderForm formDataConfig={AmendmentResourcing} />
                        </StyledGridContainer>
                      )}
                      {myValues[index].DE236 === 'Y' && (
                        <StyledGridContainer container style={{ padding: '5px', flex: 1 }}>
                          <StyledGridItem item xs={12}>
                            <StyledBoxContainer>
                              <StyledCircleContainer>
                                <StyledCircle />
                              </StyledCircleContainer>
                              <Typography variant="h3">Amendment Lead</Typography>
                            </StyledBoxContainer>
                          </StyledGridItem>
                          <RenderForm formDataConfig={AmendmentLead} />
                        </StyledGridContainer>
                      )}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
            // )
          );
        })}
        {/* END OF TEST */}

        {/* <ul>
          {fields.map((floor, index) => (
            <li key={floor.id}>
              <button
                onClick={() => {
                  remove(index);
                }}>
                REMOVE
              </button>
              <Controller
                name={`submission.contractAmendment[${index}].DE223`}
                control={control}
                // defaultValue={floor.DE223 || ''}
                render={({ field }) => <input {...field} type="text" placeholder="Name" />}
              />
              <Controller
                name={`submission.contractAmendment[${index}].DE222`}
                control={control}
                // defaultValue={floor.DE222 || 0}
                render={({ field }) => <input {...field} type="text" placeholder="Number" />}
              />
            </li>
          ))}
        </ul> */}
      </StyledGridContainer>
    </Box>
  );
};
export default ContractAmendment;
ContractAmendment.propTypes = {
  data: PropTypes.object,
  scrollToBottomHandler: PropTypes.func,
  // contractAmendmentDeleteFlag: PropTypes.func,
  savedContractAmendments: PropTypes.object,
  resetContractAmendmentHandler: PropTypes.func
};
