import { Controller, useFormContext } from 'react-hook-form';
import { Chip, Tooltip } from '@mui/material';
import { TextField } from '@mui/material';
import * as React from 'react';
import { registerFormElement } from '../../utils/helper';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { StyledLabelBox, StyledMultiAutocomplete } from './styled';
import { protectedResources } from '../../features/auth/config';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { useContext, useRef } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { UserContext, USER_TYPE_SUPER } from 'features/auth/userContext';

export const MultiEnumeratedElementComponent = ({ inputDataObject, isDisplayed }) => {
  const userInfo = useContext(UserContext);
  const { commonVendorOptions, setCommonVendorOptions } = useContext(ThemeContext);
  const timeoutRef = useRef(null);
  const { control } = useFormContext();
  const { execute } = useFetchWithMsal();
  if (!isDisplayed) {
    return null;
  }
  const handleInputChange = async (event, newInputValue) => {
    if (newInputValue === '') {
      return;
    }
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to call the API after the user has stopped typing for 500ms
    timeoutRef.current = setTimeout(async () => {
      try {
        const response = await execute(
          'GET',
          protectedResources.apiCreateList.getCommonVendorEndpoint(newInputValue)
        );
        setCommonVendorOptions((prev) => {
          const updatedResponse = response.filter((res) => {
            let isActive = true;
            if (userInfo?.userType !== USER_TYPE_SUPER) {
              isActive = res?.isActive === 'Y' ? true : false;
            }
            return isActive;
          });
          const newArray = [...prev, ...updatedResponse];
          return newArray.filter((obj, index, arr) => {
            return index === arr.findIndex((o) => o.id === obj.id);
          });
        });

        // Reset the value of timeoutRef.current
        timeoutRef.current = null;
      } catch (error) {
        console.log('lets see the ereor', error);
      }

      // Call your API with the new input value
      // const response = await fetch(`/my-api?query=${newInputValue}`);
      // const data = await response.json();
    }, 1500);
  };
  return (
    <>
      <Controller
        name={registerFormElement(inputDataObject)}
        control={control}
        render={({ field: { onChange, value } }) => {
          const fieldValue =
            value && value.length > 0
              ? commonVendorOptions.filter((item) => value.includes(item.id))
              : [];
          return (
            <StyledMultiAutocomplete
              // disablePortal
              id="combo-box-demo"
              // options={DE014List}
              options={commonVendorOptions}
              fullWidth
              multiple
              disablePortal={false}
              onInputChange={handleInputChange}
              // value={value}
              value={fieldValue}
              isOptionEqualToValue={(options, value) => {
                // console.log('testing optins and vales', options, value);
                return options.id === value.id;
              }}
              sx={{
                m: 0.5,
                '& .MuiAutocomplete-tag': {
                  maxWidth: 100,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }
              }}
              getOptionLabel={(option) => {
                // console.log('lets test option', option);
                return option.name;
              }}
              onChange={(e, newValue) => {
                // console.log('NEWVALUE multiselect', newValue);
                let ids;
                if (newValue === null || newValue.length === 0) {
                  ids = [];
                } else {
                  ids = newValue.map((item) => item.id);
                }
                onChange(ids);
                // handleCellEdit(params.row.id - 1, 'DE061', ids);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <StyledLabelBox>
                      {inputDataObject.name}
                      {inputDataObject.hasDescription && (
                        <Tooltip title={inputDataObject.description}>
                          <HelpOutlineIcon
                            fontSize="small"
                            sx={{ ml: 1, verticalAlign: 'middle', pointerEvents: 'auto' }}
                          />
                        </Tooltip>
                      )}
                    </StyledLabelBox>
                  }
                />
              )}
              renderTags={(selectedItems, getTagProps) =>
                selectedItems.map((item, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip label={item.name} title={item.name} {...getTagProps({ index })} />
                ))
              }
            />
          );
        }}
      />
    </>
  );
};
MultiEnumeratedElementComponent.propTypes = {
  inputDataObject: PropTypes.object,
  isDisplayed: PropTypes.bool
};
